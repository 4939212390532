import React from 'react';
import ImageText from './ImageText';
import Autoclue1 from '../images/autoclue-1.svg';
import Autoclue2 from '../images/autoclue-2.svg';
import Autoclue3 from '../images/autoclue-3.svg';
import Autoclue4 from '../images/autoclue-4.svg';

export default () => {
  return (
    <section id="channels" className="channels-list" style={{ padding: 0 }}>
      <ImageText
        image={Autoclue1}
        title="高价值客户识别"
        description="荟聚的线索指标管理体系包含客户行为活跃度&客户资料匹配程度，待完成预设打分规则，建立客户价值矩阵模型后，除了识别已有潜客群体中高频互动的高质量潜客，定向输出内容或定制互动旅程外，还可以将成熟度高的线索自动分配给SDR/销售，提升工作效率。 "
        imageSize={6}
        position="right"
        bgColor="yellow"
        showDemoBtn
      />
      <ImageText
        image={Autoclue2}
        title="自动化客户旅程"
        description="市场上已经对产品价值感兴趣的用户数量比例是很少的，销售打电话介绍产品，直接挂断的都是不感兴趣的，需要进行认知培育。所以在认识的不同阶段，需要提供给用户的引导也不同，设置定制化的客户培育旅程，加速转化。认知阶段：介绍产品价值主张；考量阶段：突出产品对了解者的价值；比较/购买阶段：发掘客户的具体需求，并给予满足。"
        imageSize={6}
        bgColor="white"
        showDemoBtn
      />
      <ImageText
        image={Autoclue3}
        title="沉睡客户唤醒"
        description="由于B2B客户决策周期和身份的复杂性，短期内很难像B2C一样纯靠基于用户路径和画像的算法就能准确判断需求、挖掘商机；但营销自动化可以将重复性营销活动/流程，自动化执行，对营销数据、效果进行实时追踪、整合与分析，可以简化营销环节的人工操作，自动向SDR/销售推送客户行为轨迹，准确判断客户需求，快速甄别高意向客户。"
        imageSize={6}
        position="right"
        bgColor="yellow"
        showDemoBtn
      />
      <ImageText
        image={Autoclue4}
        title="提升客户全生命周期价值"
        description="以存量找增量，ABM高效助力获客转化，协同市场/售后，如面向企服等行业，对即将到期的客户，自动给跟进人下发续费提醒任务，避免员工的遗漏导致客户流失；同时基于业务目标，使用多维标签对客户进行分层，群发针对性内容，不断激发成交客户的潜在需求，A/B测试寻找合适的增购机会。"
        imageSize={6}
        bgColor="white"
        showDemoBtn
      />
    </section>
  );
};
