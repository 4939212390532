import React from 'react';
import Banner2 from '../components/Banner2';
import AutoclueImageTextList from '../components/AutoclueImageTextList';
import Layout from '../components/Layout';
import CasesBanner from '../images/scenes-banner.svg';
import SceneSolutions from '../components/SceneSolutions';
import Pain7 from '../images/pain-7.svg';
import Pain8 from '../images/pain-8.svg';
import Pain9 from '../images/pain-9.svg';
import PainsAndNeeds from '../components/PainsAndNeeds';
import SceneSolutionsImg3 from '../images/scenes-solutions-3.png';

const Ptitle3 =
  '根据多种营销场景，通过自动化流程提升线索转化率，个性化触达潜在\n客户，预测客户行为，发现优质商机';

const VALUE_DATA = [
  {
    id: 'value-1',
    title: '长尾线索严重浪费',
    main: '',
    description: '对短期低价值线索缺少持续沟通和培养，线索流失率高',
    iconId: Pain7,
  },
  {
    id: 'value-2',
    title: '无法激活沉寂线索',
    main: '',
    description: '停留在千人一面的线索培育，无法提供个性化服务',
    iconId: Pain8,
  },
  {
    id: 'value-3',
    title: '无法发现高质量线索',
    main: '',
    description: '无法根据实时客户行为洞察客户意向，发现优质商机',
    iconId: Pain9,
  },
];

export default () => (
  <Layout current="scenes" subCurrent="autoclue">
    <Banner2
      title="自动化线索培育"
      Ptitle3={Ptitle3}
      backgroundImage={CasesBanner}
      showDemoBtn
      buttonText="体验场景DEMO"
    />
    <PainsAndNeeds data={VALUE_DATA} />
    <SceneSolutions src={SceneSolutionsImg3} />
    <AutoclueImageTextList />
  </Layout>
);
